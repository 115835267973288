import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <nav className="navbar">
        <div className="brand-title">MobilEyes</div>
        <ul>
          <li><a href="#privacyPolicy">Privacy Policy</a></li>
        </ul>
      </nav>
      <div className="privacyPolicy" id="privacyPolicy">
        <h2>Privacy Policy</h2>
        <p style={{ fontStyle: 'italic' }}>Effective Date: September 20th, 2024</p>

        <p>MobilEyes ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use the MobilEyes mobile application ("App"). Please read this Privacy Policy carefully. By using the App, you consent to the data practices described in this policy. If you do not agree to the terms of this Privacy Policy, please do not use the App.</p>

        <h3>1. Information We Collect</h3>
        <p>We collect several types of information from users for various purposes to provide and improve our App.</p>

        <h4>1.1 Personal Information</h4>
        <p>When using the App, we may collect personally identifiable information that can be used to contact or identify you, including but not limited to:</p>
        <ul>
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Location data (if enabled)</li>
          <li>Emergency contacts</li>
        </ul>
        <p>This information is collected when you:</p>
        <ul>
          <li>Register for an account.</li>
          <li>Use the "Call a Lawyer" feature.</li>
          <li>Share a live stream or recording.</li>
        </ul>

        <h4>1.2 Device Information</h4>
        <p>We collect information about your mobile device, including:</p>
        <ul>
          <li>Device type (smartphone, tablet, etc.)</li>
          <li>Operating system (iOS, Android, etc.)</li>
          <li>Device identifier (such as unique device ID)</li>
          <li>Mobile network information</li>
        </ul>

        <h4>1.3 Location Data</h4>
        <p>If you grant us permission, we collect precise geolocation data in real time while the App is running. Location data is used to:</p>
        <ul>
          <li>Help identify your location during traffic stops.</li>
          <li>Assist lawyers or emergency contacts in case of escalation.</li>
        </ul>
        <p>You can manage your location preferences through your device settings.</p>

        <h4>1.4 Usage Data</h4>
        <p>We collect information on how you access and interact with the App, including:</p>
        <ul>
          <li>App usage data (time spent in the app, pages visited, etc.)</li>
          <li>Crash reports and performance data</li>
          <li>Recording duration and interaction timestamps</li>
        </ul>

        <h3>2. How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li><b>App functionality</b>: To enable core features, such as recording, live streaming, and calling a lawyer.</li>
          <li><b>Safety</b>: To allow emergency contacts or lawyers to assist in situations where legal advice or de-escalation may be necessary.</li>
          <li><b>Communication</b>: To send you notifications, updates, and security alerts regarding the App.</li>
          <li><b>Improvement</b>: To analyze usage and improve the performance and functionality of the App.</li>
        </ul>
        <p>We do not sell or rent your personal information to third parties.</p>

        <h3>3. Sharing Your Information</h3>
        <p>We may share your information under the following circumstances:</p>

        <h4>3.1 With Lawyers</h4>
        <p>When you use the "Call a Lawyer" feature, we share your live stream, contact details, and any relevant information with the lawyer you connect with. This information is used solely for the purpose of providing legal advice during the interaction.</p>

        <h4>3.2 With Emergency Contacts</h4>
        <p>If you choose to alert a designated emergency contact, we may share your location and live stream details with them.</p>

        <h4>3.3 Legal Requirements</h4>
        <p>We may disclose your information if required to do so by law or in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>Comply with legal obligations.</li>
          <li>Protect and defend our rights or property.</li>
          <li>Prevent or investigate wrongdoing related to the App.</li>
          <li>Protect the personal safety of users or the public.</li>
        </ul>

        <h3>4. Data Security</h3>
        <p>We take reasonable measures to protect your personal information. However, please be aware that no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the security of your data, and you transmit your information at your own risk.</p>

        <h3>5. Data Retention</h3>
        <p>We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. You may request the deletion of your account and associated data by contacting us at [Insert Contact Information].</p>

        <h3>6. Your Privacy Rights</h3>
        <p>Depending on your location, you may have certain rights regarding your personal data, such as:</p>
        <ul>
          <li><b>Access</b>: You have the right to request a copy of the personal data we hold about you.</li>
          <li><b>Correction</b>: You have the right to correct inaccurate or incomplete information.</li>
          <li><b>Deletion</b>: You may request that we delete your personal information, subject to certain legal obligations.</li>
          <li><b>Withdrawal of Consent</b>: Where consent is required for processing, you may withdraw your consent at any time.</li>
        </ul>
        <p>To exercise any of these rights, please contact us at [Insert Contact Information].</p>

        <h3>7. Third-Party Services</h3>
        <p>The App may contain links to third-party services or websites, including lawyers or legal services accessed through the App. We are not responsible for the privacy practices of these third parties. Please review the privacy policies of any third-party services you engage with.</p>

        <h3>8. Children's Privacy</h3>
        <p>MobilEyes is not intended for use by children under the age of 18. We do not knowingly collect personal information from anyone under the age of 18. If we become aware that we have collected information from a child, we will take steps to delete such information.</p>

        <h3>9. Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by posting the updated policy in the App or through other communication methods. Your continued use of the App following any updates indicates your acceptance of the changes.</p>

        <h3>10. Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <address>
          mobileyes<br />
          mobileyesappdev@gmail.com<br />
        </address>

        <p>By using MobilEyes, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
      </div>
    </div>
  );
}

export default App;
